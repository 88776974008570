import convertToSlug from '~/utility/convertToSlug';
import type { ICategoryApiResponse, ICategoryApi, ICategoryResponse, ICategorySingle, ICategory, ICategoryApiSingleResponse } from '~/types/categories';

/**
 * Retrieves a paginated list of categories.
 *
 * @returns {Promise<ICategoryPaginate>} - A promise that resolves to a paginated list of categories.
 */
export async function getCategories (areaId: number): Promise<ICategoryApi> {
    const appConfig = useAppConfig();
    let response: ICategoryApiResponse;

    if (appConfig.useMockData) {
        response = await $fetch('/mock/categories.json');
    } else {
        response = await useAPI<ICategoryApiResponse>(`/v1/area/${areaId}/category`, {
            method: 'GET',
        });
    }
    return {
        data: response.data.map((category: ICategoryResponse) => formatCategory(category)),
        meta: response.meta
    };
}

/**
 * Retrieves a paginated list of categories by Account Context ID
 *
 * @returns {Promise<ICategoryPaginate>} - A promise that resolves to a paginated list of categories.
 */
export async function getCategoriesByAccountContextId (accountContextId: number): Promise<ICategoryApi> {
    const response = await useAPI<ICategoryApiResponse>(`/v1/account-context/${accountContextId}/category`, {
        method: 'GET',
    });

    return {
        data: response.data.map((category: ICategoryResponse) => formatCategory(category)),
        meta: response.meta
    };
}

/**
 * Retrieves a single category by its ID.
 *
 * @param {number} categoryId - The ID of the category to retrieve.
 * @returns {Promise<ICategorySingle>} - A promise that resolves to the details of a single category.
 */
export async function getCategory (categoryId: number): Promise<ICategorySingle> {
    const appConfig = useAppConfig();
    let response: ICategoryApiSingleResponse;

    if (appConfig.useMockData) {
        response = await $fetch('/mock/category/single.json');
    } else {
        response = await useAPI<ICategoryApiSingleResponse>(`/v1/admin/category/${categoryId}`, {
            method: 'GET',
        });
    }

    return formatCategory(response.data);
}

/**
 * Formats a single Category response from the server into a front end object
 * @param {ICategoryResponse} category A response object received from the API
 * @returns {ICategory}
 */
function formatCategory (category: ICategoryResponse): ICategory {
    return {
        ...category,
        slug: convertToSlug(category),
    };
}
