import { usePublicDirectAppDataStore } from './appdata-public-direct';
import { useAccountDirectAppDataStore } from './appdata-account-direct';
import type { ICategory } from '~/types/categories';
import type { IService } from '~/types/services';

export interface ICommonDataState {
    isCategoriesLoading: boolean;
    isServicesLoading: boolean;

    categoryLoadError: string | null;
    serviceLoadError: string | null;

    hasCategoriesLoaded: boolean;
    hasServicesLoaded: boolean;

    categories: ICategory[];
    services: IService[];
    categoryName: string;
    categoryId: number | null;
}

export interface ICommonDataActions {
    setCategoryName: (name: string) => void;
    setCategoryId: (id: number | null) => void;
    loadCategories: () => Promise<ICategory[]>;
    loadServices: () => Promise<IService[]>;
    getCategoryUrl: (category: ICategory) => string;
}

export interface ICommonStore extends ICommonDataState, ICommonDataActions {}

const { isPublicDirect } = useAppMode();

export const useAppDataStore = (function () {
    return isPublicDirect ? usePublicDirectAppDataStore : useAccountDirectAppDataStore;
})();
