import { acceptHMRUpdate, defineStore } from 'pinia';

import type { ICommonDataActions, ICommonDataState } from './appdata';

import useContextSlug from '~/composables/use-context-slug';
import type { ICategory } from '~/types/categories';

export interface IAccountDirectDataState extends ICommonDataState { }
export interface IAccountDirectDataActions extends ICommonDataActions {}

export interface IAccountDirectStore extends IAccountDirectDataState, IAccountDirectDataActions {}

export const useAccountDirectAppDataStore = defineStore({
    id: 'data',

    state: () => ({
        categoryLoadError: '',
        serviceLoadError: '',

        isCategoriesLoading: false,
        isServicesLoading: false,

        hasCategoriesLoaded: false,
        hasServicesLoaded: false,

        categories: [],
        services: [],
        categoryName: '',
        categoryId: 0,
    } as IAccountDirectDataState),
    actions: {
        setCategoryName (name: string) {
            this.categoryName = name;
        },
        setCategoryId (id: number | null) {
            this.categoryId = id;

            if (id) {
                this.hasServicesLoaded = false;
                this.services = [];
                this.loadServices();
            }
        },

        async loadCategories () {
            const { accountContext } = useContextSlug();

            if (!accountContext.value) { return; }

            this.services = [];

            this.categoryLoadError = null;
            this.isCategoriesLoading = true;

            try {
                const data = await getCategoriesByAccountContextId(accountContext.value.id);

                this.categories = data?.data || [];

                this.hasCategoriesLoaded = true;
            } catch (error: any) {
                this.categoryLoadError = 'There was an error loading the categories for this Area. Please try again later.';
            } finally {
                this.isCategoriesLoading = false;
            }

            return this.categories;
        },
        async loadServices () {
            const { accountContext } = useContextSlug();

            if (!this.categoryId || this.hasServicesLoaded || !accountContext.value) { return; }

            this.serviceLoadError = null;
            this.isServicesLoading = true;

            try {
                const data = await getServicesByAccountContextId(accountContext.value.id, this.categoryId);

                this.services = data?.data || [];
                this.hasServicesLoaded = true;
            } catch (error: any) {
                this.serviceLoadError = 'There was an error loading the services. Please try again later.';
            } finally {
                this.isServicesLoading = false;
            }

            return this.services;
        },

        getCategoryUrl (category: ICategory) {
            return `/${category.slug}/${category.id}`;
        },
    } as IAccountDirectStore,
});
