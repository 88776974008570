import convertToSlug from '~/utility/convertToSlug';
import type { IAreaApiResponse, IAreaApi, IAreaResponse, IAreaSingle, IArea, IAreaApiSingleResponse } from '~/types/areas';

/**
 * Retrieves a paginated list of areas.
 *
 * @returns {Promise<IAreaPaginate>} - A promise that resolves to a paginated list of areas.
 */
export async function getAreas (): Promise<IAreaApi> {
    const appConfig = useAppConfig();
    let response: IAreaApiResponse;

    if (appConfig.useMockData) {
        response = await $fetch('/mock/area/paginate.json');
    } else {
        response = await useAPI<IAreaApiResponse>('/v1/area', {
            method: 'GET',
        });
    }

    return {
        data: response.data.map((area: IAreaResponse) => formatArea(area)),
        meta: response.meta
    };
}

/**
 * Retrieves a single area by its ID.
 *
 * @param {number} areaId - The ID of the area to retrieve.
 * @returns {Promise<IAreaSingle>} - A promise that resolves to the details of a single area.
 */
export async function getArea (areaId: number): Promise<IAreaSingle> {
    const appConfig = useAppConfig();
    let response: IAreaApiSingleResponse;

    if (appConfig.useMockData) {
        response = await Promise.resolve({
            data: {
                id: 6,
                name: 'Santa Barbara',
            },
            meta: {},
            success: true,
        });
    } else {
        response = await useAPI<IAreaApiSingleResponse>(`/v1/area/${areaId}`, {
            method: 'GET',
        });
    }

    return formatArea(response.data);
}

/**
 * Formats a single Area response from the server into a front end object
 * @param {IAreaResponse} area A response object received from the API
 * @returns {IArea}
 */
function formatArea (area: IAreaResponse): IArea {
    return {
        ...area,
        slug: convertToSlug(area),
    };
}
