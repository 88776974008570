import { defineStore } from 'pinia';

import type { ICommonDataActions, ICommonDataState } from './appdata';
import type { IArea } from '~/types/areas';
import type { ICategory } from '~/types/categories';
import { IService } from '~/types/services';

export interface IPublicDirectDataState extends ICommonDataState {
    isAreasLoading: boolean;
    areaLoadError: string | null;
    hasAreasLoaded: boolean;

    areas: IArea[];
    areaName: string;
    areaId: number;

    selectedArea: IArea | undefined;
}

export interface IPublicDirectDataActions extends ICommonDataActions {
    loadAreas: () => Promise<IArea[]>;
    setAreaName: (name: string) => void;
    setAreaId: (id: number) => void;
    updateSelectedArea: (id: number) => void;
}

export interface IPublicDirectStore extends IPublicDirectDataState, IPublicDirectDataActions {}

export const usePublicDirectAppDataStore = defineStore({
    id: 'data',

    state: () => ({
        areaLoadError: '',
        categoryLoadError: '',
        serviceLoadError: '',

        isAreasLoading: false,
        isCategoriesLoading: false,
        isServicesLoading: false,

        hasAreasLoaded: false,
        hasCategoriesLoaded: false,
        hasServicesLoaded: false,

        areas: [],
        categories: [],
        services: [],
        areaName: '',
        areaId: 0,
        categoryName: '',
        categoryId: 0,

        selectedArea: undefined,
    } as IPublicDirectDataState),
    actions: {
        setAreaName (name: string) {
            this.areaName = name;
        },
        setAreaId (id: number) {
            this.areaId = id;

            this.updateSelectedArea(id);
        },
        setCategoryName (name: string) {
            this.categoryName = name;
        },
        setCategoryId (id: number | null) {
            this.categoryId = id;

            if (id) {
                this.hasServicesLoaded = false;
                this.services = [];
                this.loadServices();
            }
        },
        updateSelectedArea (id: number) {
            this.selectedArea = this.areas.find(area => area.id === id);
        },
        async loadAreas () {
            this.areaLoadError = null;
            this.isAreasLoading = true;

            try {
                const data = await getAreas();

                this.areas = data?.data || [];

                this.updateSelectedArea(this.areaId);

                this.hasAreasLoaded = true;
            } catch (error: any) {
                this.areaLoadError = 'There was an error loading the Areas. Please try again later.';
            } finally {
                this.isAreasLoading = false;
            }

            return this.areas;
        },
        async loadCategories () {
            if (!this.areaId) { return; }

            this.services = [];

            this.categoryLoadError = null;
            this.isCategoriesLoading = true;

            try {
                const data = await getCategories(this.areaId);

                this.categories = data?.data || [];

                this.hasCategoriesLoaded = true;
            } catch (error: any) {
                this.categoryLoadError = 'There was an error loading the categories for this Area. Please try again later.';
            } finally {
                this.isCategoriesLoading = false;
            }

            return this.categories;
        },
        async loadServices () {
            if (!this.categoryId || this.hasServicesLoaded) { return; }

            this.serviceLoadError = null;
            this.isServicesLoading = true;

            try {
                const data = await getServices(this.areaId, this.categoryId);

                this.services = data?.data || [];
                this.hasServicesLoaded = true;
            } catch (error: any) {
                this.serviceLoadError = 'There was an error loading the services. Please try again later.';
            } finally {
                this.isServicesLoading = false;
            }

            return this.services;
        },

        getCategoryUrl (category: ICategory) {
            return `/${this.selectedArea?.slug}/${this.areaId}/${category.slug}/${category.id}`;
        },
    } as IPublicDirectStore,
});
